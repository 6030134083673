/* styles.css */

.custom-title-shape {
    clip-path: polygon(0 0, 95% 0, 85% 100%, 0% 100%);
    background: linear-gradient(90deg, rgba(0,36,61,1) 0%, rgba(0,149,209,1) 100%);
    border-bottom: 2px solid rgba(0,149,209,1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.6);
    padding-left: 1rem;
  }
  .custom-subtitle-shape {
    clip-path: polygon(0 0, 95% 0, 55% 100%, 0% 100%);
    background: linear-gradient(50deg, rgba(0,36,61,1) 0%, rgba(0,149,209,1) 100%);
    border-bottom: 2px solid rgba(0,149,209,1);
    box-shadow: 0 4px 6px rgba(0,0,0,0.6);
    padding-left: 1rem;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .text-animation {
    animation: fadeIn 0.5s ease-in-out;
  }
  .object-fit-cover {
    object-fit: cover;
  }
  
  .video-container {
    position: relative;
  }
  
  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .bg-dark-green {
    background-color: #005a00;
}

.bg-selected {
    background-color: #7f6a00;
    border: 2px solid #fff700;
    box-shadow: 0 0 10px #fff700, 0 0 20px #fff700, 0 0 30px #fff700;
}

.neon-green {
    box-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14, 0 0 15px #39ff14, 0 0 20px #39ff14;
}

.neon-yellow {
    box-shadow: 0 0 5px #fffc00, 0 0 10px #fffc00, 0 0 15px #fffc00, 0 0 20px #fffc00;
}
.neon-red {
  box-shadow: 0 0 5px #b80606, 0 0 10px #c8440b, 0 0 15px #eb0505, 0 0 20px #ff0000;
}

.neon-text {
    color: #FFFFFF;
    text-shadow: 0 0 2px #fff700, 0 0 4px #fff700, 0 0 6px #fff700, 0 0 8px #fff700;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}