/* Landscape.css */

/* Starry background animation */
@keyframes starry {
    from { background-position: 0 0; }
    to { background-position: -10000px 10000px; }
}

.bg-galaxy {
    background: url('../../img/landingpagebg.png.jpg') repeat;
    background-size: cover;
    background-attachment: fixed;
    animation: starry 500s linear infinite;
}

.glow-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #ffffff; /* Clean white text */
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: none; /* Remove the glow effect */
}


.card-glow {
    background: rgba(255, 255, 255, 0.15); /* Less transparent */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
    border-radius: 10px; /* Smooth corners */
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.card-glow:hover {
    transform: translateY(-5px); /* Gentle hover lift */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* More prominent shadow on hover */
}



.carousel-controls {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: background 0.3s ease;
}

.carousel-controls:hover {
    background: rgba(255, 255, 255, 0.6); /* Slight hover effect */
}
/* Landingcss.css */
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  