@tailwind base;
@tailwind components;
@tailwind utilities;
/* Responsive adjustments using TailwindCSS */
@media (min-width: 640px) { /* Adjust for medium screens and up */
  .text-2xl {
    font-size: 1.5rem; /* Smaller text on smaller screens */
  }
}

@media (min-width: 768px) { /* Adjust for large screens */
  .grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 columns on smaller screens */
  }
}

/* Styles the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

/* Styles the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #060c55; /* Thumb color */
}

/* Handle the scrollbar thumb hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Optional: Styles the scrollbar itself (width, height) */
::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

@font-face {
  font-family: "Yekan";
  font-weight: 400;
  src: url('./assets/font/iranyekanwebmedium.woff') format('woff')  ,

       
}
@font-face {
  font-family: "Yekan";
  font-weight: 100;
  src: url('./assets/font/iranyekanwebthin.woff') format('woff')  ,

       
}

@font-face {
  font-family: "Yekan";
  font-weight:800;
  src:   url('./assets/font/iranyekanwebblack.woff') format('woff')    ,
    
    
      
     
}
@font-face {
  font-family: "Yekan";
  font-weight: 600;
  src:  url('./assets/font/iranyekanwebbold.woff')  format('woff')  ,
    
    
      
     
}
@font-face {
  font-family: "Yekan";
  font-weight: 200;
  src: url('./assets/font/iranyekanweblight.woff') format('woff')   ,
    
      

}


p , h1 , h2 , h3 , h4 , button , li , a , input ,th,tr,td {
  font-family: "yekan";

  
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  #card>*:hover{
  color:rgb(255, 255, 255) !important;
}
