@media screen and (max-width: 768px) {
  .chatbot-container {
    width: 100%;
    max-width: 300px;
    height: auto;
    right: 16px;
    bottom: 16px;
  }

  .chatbot-toggle-button {
    width: 60px;
    height: 60px;
  }

  .chatbot-header {
    padding: 8px;
  }

  .chatbot-input {
    padding: 8px;
  }
}
