@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink {
    50% { border-color: transparent }
}

.typing-effect {
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid;
    animation: typing 3s steps(40, end), blink 0.75s step-end infinite;
}

.slogan-effect {
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid;
    animation: typing 4s steps(40, end) 3s forwards, blink 0.75s step-end infinite;
}
