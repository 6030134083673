/* LoginPage.css */
.login-page {
    background-color: #1a202c; /* Dark background color */
    overflow: hidden;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes thunder {
    0%, 100% {
      opacity: 0;
      background: rgba(255, 255, 255, 0);
    }
    50% {
      opacity: 0.6;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  
  .login-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    animation: thunder 3s infinite ease-in-out;
  }
  
  .login-container {
    z-index: 10;
    padding: 40px;
    background-color: #2d3748;
    border-radius: 8px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 400px;
  }
  
  .login-title {
    margin-bottom: 24px;
    color: #fff;
    text-align: center;
    font-size: 28px;
  }
  
  .login-input {
    background: #4a5568;
    color: #fff;
    border: none;
    margin-bottom: 16px;
    padding: 10px 15px;
    width: calc(100% - 30px);
    border-radius: 4px;
  }
  
  .login-button {
    background: #3182ce;
    color: #fff;
    border: none;
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .login-button:hover {
    background: #2c5282;
  }
  