/* Marker Icon Styling */
.custom-marker .fa-icon-marker {
  font-size: 24px; /* FontAwesome icon size */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3); /* Transparent background */
  border: 2px solid rgba(255, 255, 255, 0.7); /* Slight border */
  border-radius: 50%; /* Make it circular */
  padding: 5px;
}

.marker-legend {
  display: none; /* Initially hidden */
}

.custom-marker:hover .marker-legend {
  display: block;
  position: absolute;
  background-color: rgb(90, 81, 81);
  padding: 12px; /* Increased padding for better spacing */
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  top: -60px; /* Adjusted position for better visibility */
  left: 10px;
  font-size: 16px; /* Increased font size for better readability */
  color: white; /* Ensure the text is visible against the background */
  white-space: nowrap; /* Prevent text wrapping */
  z-index: 10; /* Ensure it appears above other elements */
}

/* Custom Popup Styling */
.leaflet-popup-content-wrapper.custom-popup {
  background-color: rgba(50, 50, 50, 0.9); /* Gray background */
  color: rgb(236, 17, 17); /* White text */
  padding: 12px;
  border-radius: 6px;
}

.leaflet-popup-content-wrapper.custom-popup .leaflet-popup-content {
  margin: 0;
  font-size: 14px; /* Adjust the text size */
}

.leaflet-popup-tip.custom-popup {
  background-color: rgba(50, 50, 50, 0.9); /* Match tooltip background to popup */
}

